<template>
    <div class="edit-store-class">
        <div class="title-border">编辑FunPinPin店铺</div>
        <!--form 表单-->
        <div>
            <div class="basis-list">
                <span class="name">店铺名称</span>
                <span class="content">
                           {{storeName}}
                </span>
            </div>
            <div class="basis-list">
                <span class="name">后台地址</span>
                <span class="content">
                            <a :href="storeDomain" target="_blank">{{storeAddress}}</a>
                        </span>
            </div>
            <div class="basis-list">
                <span class="name">*接收信息邮箱</span>
                <span class="content">
                             <PaInput
                                     style="width: 353px;"
                                     class="emailInput"
                                     v-model="from.email"
                                     type="(^1[3-9][0-9]{9}$)|(^\w+((-w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$)"
                                     placeholder="请输入登录邮箱"
                                     error_content="请输入正确的邮箱"
                                     :submitType="submitType"
                                     :is_required="true"
                             />
                        </span>
            </div>
        </div>
        <!--取消&确定按钮-->
        <div class="btn">
            <span class="cancel" @click="handleCancel()">取消</span>
            <span class="sure" @click="handleSure()">确定</span>
        </div>
    </div>
</template>

<script>
    import PaInput from "@/components/basic/PaInput.vue";

    export default {
        name: "editStore",
        props: ["storeName", "storeAddress", "email"],
        components: {PaInput},
        data() {
            return {
                from: {
                    email: {value: this.email, isError: false},
                },
                submitType: false,
            };
        },
        methods: {
            handleCancel() {
                this.$parent.$parent.editStoreDialog = false
            },
            handleSure() {
                this.$parent.$parent.editStoreDialog = false
            }

        },
    }
</script>
<style>
    .emailInput .input-box input {
        min-height: 40px !important;
    }
</style>
<style scoped lang="scss">
    .edit-store-class {
        font-size: 16px;
        font-weight: 400;
        .basis-list {
            font-size: 16px;
            font-weight: 400;
            padding: 19px 0;
            border-bottom: 1px solid #e2d6cb;
            display: flex;
            align-items: baseline;
            justify-content: center;
            .name {
                color: #312f2e;
                width: 120px;
                font-weight: 400;
                display: inline-block;
            }
            .content {
                color: #312F2E;
                display: inline-block;
                width: calc(100% - 120px);
                font-weight: 400;
                a {
                    color: #1890FF;
                    line-height: 27px;
                }
            }
        }
        .title-border {
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 29px;
            /*border-bottom: 1px solid #E2D6CB;*/
        }
        .form-title {
            margin-top: 20px;
            margin-bottom: 10px;
            border-bottom: 1px solid #E2D6CB;
            padding-bottom: 19px;
        }
        .form-title1 {
            display: flex;
            align-items: baseline;
            border: none;
        }
        .btn {
            margin-top: 35px;
            text-align: right;
            span {
                font-size: 16px;
                margin-right: 10px;
                display: inline-block;
                width: 96px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 8px;
                cursor: pointer;
                color: #fff;
            }
            .cancel {
                background: #B7BEBC;
            }
            .sure {
                background: #54AB8E;
            }
        }
    }
</style>