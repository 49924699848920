<template>
  <div class="store-list-class content-height">
    <section class="center-detail">
      <!-- 条件筛选 -->
      <div class="filter-condition">
        <!--<el-select class="margin-r30" v-model="selectValue1" placeholder="店铺套餐">
            <el-option
                    v-for="item in selectList1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
            ></el-option>
        </el-select>
        <el-select class="margin-r30" v-model="selectValue2" placeholder="套餐状态">
            <el-option
                    v-for="item in selectList2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
            ></el-option>
        </el-select>-->
        <div class="search-input">
          <el-autocomplete
                  class="w460"
                  v-model="state"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入店铺名称"
                  @select="handleSelect"
          >
            <template slot-scope="{item}">
              <div>{{item.store_name}}</div>
            </template>
          </el-autocomplete>
          <i @click="storeNameSearch()" class="el-icon-search"></i>
        </div>
      </div>
      <!-- 店铺表格 -->
      <div>
        <!--<div class="margin-b20">
            <el-checkbox v-model="checked"></el-checkbox>
            <span>已选中0项</span>
            <span class="pay-btn" @click="renewStoreDialog = true">续费店铺套餐</span>
            <span class="pay-btn" @click="payRateDialog = true">支付费率账单</span>
        </div>-->

        <!--ref="multipleTable"
        @selection-change="handleSelectionChange"-->
        <el-table
                :data="storeList"
                tooltip-effect="dark"
                class="w100"
        >
          <!-- <el-table-column
                   type="selection"
                   
                   width="55">
           </el-table-column>-->
          <el-table-column
                  label="店铺名称"
          >
            <template slot-scope="scope">
              <div>
                <span class="green-font" @click="enterStoreDetail(scope.$index, scope.row)">
                  {{ handleEmpty(scope.row.store_name)}}
                </span>
                  <span v-if="scope.row.shop_status==='trash'">（已废弃）</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
                  width="300"
                  prop="login_email"
                  label="登录邮箱"
          >
            <template slot-scope="scope">
              <div>{{ handleEmpty(scope.row.login_email)}}</div>
            </template>
          </el-table-column>
          <el-table-column
                  prop="package"
                  label="店铺套餐"
                  show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- <div>{{ handleEmpty(scope.row.package)}}</div> -->
              <div>热销版</div>
            </template>
          </el-table-column>
          <el-table-column
                  prop="state"
                  label="套餐状态"
                  show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- <div>{{ handleEmpty(scope.row.state)}}</div> -->
              <div>正常</div>
            </template>
          </el-table-column>
          <el-table-column
                  prop="create_time"
                  label="到期时间"
                  show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- <div>{{ handleEmpty(scope.row.create_time)}}</div> -->
              <div>- -</div>
            </template>
          </el-table-column>
          <el-table-column
                  label="操作">
            <template slot-scope="scope">
              <span class="green-font" size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</span>

              <span class="gray-font" size="mini"  style="margin-left:15px;">复制<el-tooltip class="tips" effect="dark" content="系统升级中，敬请期待！" placement="top"> </el-tooltip>
                <i class="el-icon-question"></i></span>
              <span v-if="scope.row.shop_status!=='trash'" style="margin-left:15px;" class="green-font" size="mini" @click="handleAbandon(scope.$index, scope.row)" >废弃</span>
              <!--<span class="line"></span>-->
              <!-- <span
                       class="green-font"
                       size="mini"
                       type="danger"
                       @click="enterBillingDetail(scope.$index, scope.row)">账单</span>-->
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <div class="resetElementClass page-position">
          <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="condition.limit"
                  layout="total, prev, pager, next, jumper"
                  :total="total">
          </el-pagination>
        </div>
      </div>
    </section>
    <!--弹窗-->
    <div>
      <div v-if="shopDetailDialog || editStoreDialog || renewStoreDialog || payRateDialog || createStoreDialog || createSuccessDialog || abandonDialog"
           class="modelMask"></div>
      <el-dialog
              class="createDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="createStoreDialog"
              :append-to-body="true"
              modal-append-to-body>
        <createStore v-if="createStoreDialog" @storeInfoObj="storeInfoObj" :rowData="rowData" :copyFlag="true"></createStore>
      </el-dialog>
      <!--创建成功弹窗-->
      <el-dialog
              class="createStoreSuccessDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="createSuccessDialog"
              :append-to-body="true"
              modal-append-to-body
              @open="stopSrcoll()"
              @close="openSrcoll()">
        <createSuccess v-if="createSuccessDialog" :storeInfo="storeInfo" :copyFlag="true"></createSuccess>
      </el-dialog>
      <!--详情弹窗-->
      <el-dialog
              class="shopDetailDialogClass resetElementClass"
              width="652px"
              :show-close="true"
              :close-on-click-modal="false"
              :visible.sync="shopDetailDialog"
              :append-to-body="true"
              modal-append-to-body>
        <shopDetail v-if="shopDetailDialog" :storeName="storeName"></shopDetail>
      </el-dialog>
      <!--废弃弹窗  -->
      <el-dialog
              class="createStoreSuccessDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="abandonDialog"
              :append-to-body="true"
              modal-append-to-body
              @open="stopSrcoll()"
              @close="openSrcoll()">

        <abandonStore v-if="abandonDialog" :rowData="rowData"></abandonStore>
      </el-dialog>
      <!--编辑弹窗-->
      <el-dialog
              class="editStoreDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="editStoreDialog"
              :append-to-body="true"
              modal-append-to-body>
        <editStore v-if="editStoreDialog" :storeName="storeName" :storeAddress="storeAddress"
                   :email="email"></editStore>
      </el-dialog>
      <!--续费弹窗-->
      <el-dialog
              class="renewStoreDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="renewStoreDialog"
              :append-to-body="true"
              modal-append-to-body>
        <renewStore v-if="renewStoreDialog"></renewStore>
      </el-dialog>
      <!--支付费率弹窗-->
      <el-dialog
              class="renewStoreDialogClass resetElementClass"
              width="652px"
              :close-on-click-modal="false"
              :visible.sync="payRateDialog"
              :append-to-body="true"
              modal-append-to-body>
        <payRate v-if="payRateDialog"></payRate>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import editStore from "../dialog/editStore/index"
  import renewStore from "../dialog/renewStore/index"
  import shopDetail from "../dialog/shopDetail/index"
  import payRate from "../dialog/payRate/index"
  import createStore from "../dialog/createStore/index"
  import createSuccess from "../dialog/createSuccess/index"
  import abandonStore from "../dialog/abandonStore/index"
  import {storeManagement} from "@/assets/js/api";
  import {handleResponseErrors} from "@/assets/js/utils";

  export default {
    name: "storeList",
    components: {shopDetail, editStore, renewStore, payRate,createStore,createSuccess,abandonStore},
    data() {
      return {
        condition: {
          limit: 10,
          page: 1,
          store_name: '',
        },
        storeInfo:null,
        shopDetailDialog: false,
        editStoreDialog: false,
        renewStoreDialog: false,
        payRateDialog: false,
        createSuccessDialog:false,
        createStoreDialog:false,
        abandonDialog:false,
        storeName: '',
        storeAddress: '',
        email: '',
        checked: '',
        selectList1: [
          {
            value: "选项1",
            label: "黄金糕"
          },
          {
            value: "选项2",
            label: "双皮奶"
          },
          {
            value: "选项3",
            label: "蚵仔煎"
          },
          {
            value: "选项4",
            label: "龙须面"
          },
          {
            value: "选项5",
            label: "北京烤鸭"
          }
        ],
        selectList2: [
          {
            value: "选项1",
            label: "黄金糕"
          },
          {
            value: "选项2",
            label: "双皮奶"
          },
          {
            value: "选项3",
            label: "蚵仔煎"
          },
          {
            value: "选项4",
            label: "龙须面"
          },
          {
            value: "选项5",
            label: "北京烤鸭"
          }
        ],
        selectValue1: "",
        selectValue2: "",
        storeNameList: [],
        restaurants: [],
        state: '',
        timeout: null,
        total: null,
        storeList: [],
        multipleSelection: [],
        currentPage: 1,
        rowData:null,
      };
    },
    created() {
      this.initStoreList()
      this.initStoreName()
    },
    methods: {
      handleEmpty(value) {
        if (value) {
          return value
        } else {
          return '- -'
        }
      },
      async initStoreList(flag) {
        try {
          let data;
          if (flag === 'page') {
            data = {
              store_name: '',
              page: this.condition.page,
              limit: this.condition.limit
            }
          } else {
            this.condition.page = 1
            this.condition.limit = 10
            data = {
              store_name: this.condition.store_name,
              page: this.condition.page,
              limit: this.condition.limit
            }
          }

          let res = await storeManagement.getStoreList(data);
          if (res.code != 0) {
            handleResponseErrors(res.code, res.msg);
            return;
          }
          else {
            this.total = res.total
            this.storeList = res.store_list;
          }
        } catch (error) {
          console.log(error);
          // handleResponseErrors(9000, "服务异常，请稍后重试");
        }
      },
      async initStoreName() {
        try {
          let res = await storeManagement.getStoreName();
          if (res.code != 0) {
            handleResponseErrors(res.code, res.msg);
            return;
          }
          else {
            this.storeNameList = res.store_name_list;
          }
        } catch (error) {
          console.log(error);
          // handleResponseErrors(9000, "服务异常，请稍后重试");
        }
      },
      storeNameSearch() {
        this.condition.store_name = this.state
        this.initStoreList()
      },
      querySearchAsync(queryString, cb) {
        let results = queryString ? this.storeNameList.filter(this.createStateFilter(queryString)) : this.storeNameList;
        cb(results);
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.store_name.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
        };
      },
      handleSelect(item) {
        this.state = item.store_name
        this.storeNameSearch()

      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleCopy(index, row) {
        this.rowData = row
        this.createStoreDialog = true
      },
       handleAbandon(index, row) {
        this.rowData = row
        this.abandonDialog = true
      },
      handleEdit(index, row) {
        this.editStoreDialog = true
        this.storeName = row.store_name
        this.storeAddress = row.login_email
        this.email = row.login_email
      },
      handleDelete(index, row) {
      },
      handleSizeChange(val) {
        this.condition.limit = val
        this.initStoreList('page')
      },
      handleCurrentChange(val) {
        this.condition.page = val
        this.initStoreList('page')
      },
      enterStoreDetail(index, data) {
        this.storeName = data.store_name
        this.shopDetailDialog = true
        // this.$router.push({path: 'shopDetail', query: {store_name: data.store_name}})
      },
      enterBillingDetail(index, data) {
        this.$router.push({path: 'billingDetail', query: {id: data.id}})
      },
      storeInfoObj(v) {
        this.storeInfo = v
      },
      stopSrcoll() {
        var mo = function (e) {
          e.preventDefault();
        };
        document.body.style.overflow = 'hidden';//隐藏滚动条
        document.addEventListener("touchmove", mo, false);//禁止页面滑动
      },
      openSrcoll() {
        var mo = function (e) {
          e.preventDefault();
        };
        document.body.style.overflow = '';//出现滚动条
        document.removeEventListener("touchmove", mo, false);
      },
    },
    mounted() {
    }
  };
</script>
<style lang="scss">
.tips{
  margin-left: 10px;
}
</style>
<style scoped lang="scss">
  @import "@/assets/css/common.scss";

  .content-height {
    min-height: calc(100vh - 429px);
  }

  .store-list-class {
    .w100 {
      width: 100%;
    }
    .w460 {
      width: 460px;
    }
    .margin-r30 {
      margin-right: 30px
    }
    .margin-b20 {
      margin-bottom: 20px;
    }
    .center-detail {
      /*margin-top: 24px*/
    }
    .search-input {
      display: inline-block;
      position: relative;
      i {
        position: absolute;
        right: 14px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
        color: #AEA18F;
      }
    }
    .page-position {
      margin-top: 31px;
      text-align: right;
    }
    .filter-condition {
      display: flex;
      /*justify-content: flex-end;*/
      margin-bottom: 24px;
    }
    .line {
      display: inline-block;
      width: 1px;
      height: 20px;
      margin: 0 10px;
      vertical-align: middle;
      background: rgba(226, 214, 203, 0.6);
    }
    .green-font {
      color: $green;
      cursor: pointer;
    }
    .gray-font{
      color: #c0c4cc;
      cursor: not-allowed;
    }
    .pay-btn {
      display: inline-block;
      text-align: center;
      line-height: 44px;
      color: #fff;
      background: $green;
      width: 180px;
      height: 44px;
      margin-left: 20px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
</style>
