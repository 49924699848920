<template>
  <div class="abandon-store-class">
    <div class="title-border">废弃店铺</div>
    <!--form 表单-->
    <div class="abandon-content">
        如确定废弃店铺，当前店铺会进入废弃状态，该操作不可逆，请谨慎操作！
    </div>
    <!--取消&确定按钮-->
    <div class="btn">
      <el-button class="cancel" @click="handleCancel()">取消</el-button>
      <el-button class="sure" @click="handleSure()" :loading="showLoading">确定</el-button>
    </div>
  </div>
</template>

<script>
  import {storeManagement} from "@/assets/js/api";
  import {handleResponseErrors} from "@/assets/js/utils";

  export default {
    name: "abandonStore",
    data() {
      return {
        from: {
          storeName: {value: "", isError: false},
          email: {value: "", isError: false},
        },
        submitType: false,
        createSuccessDialog: false,
        storeName: '',
        showLoading: false,
      };
    },
    props: [
        "rowData"
    ],
    methods: {
      handleCancel() {
        if (!this.showLoading) {
          this.$parent.$parent.abandonDialog = false
        }
      },
      async handleSure() {
        this.showLoading = true;
        let data={}

        try {
          let res = null;
          res = await storeManagement.abandonStore(this.rowData.store_name);
          if (res.code !== 0) {
            this.showLoading = false
            handleResponseErrors(res.code, res.msg);
            return;
          }
          else {
            this.showLoading = false
            this.$parent.$parent.abandonDialog = false
            this.$parent.$parent.initStoreList()
          }
        } catch (error) {
          this.showLoading = false;
          console.log(error);
          handleResponseErrors(9000, "服务异常，请稍后重试");
        }
      },
    },
  }
</script>
<style scoped lang="scss">
  .abandon-store-class {
    .abandon-content{
     padding: 60px;
    line-height: 43px;
    }
    .h120 {
      height: 120px;
    }
    .title-border {
      font-size: 18px;
      padding-bottom: 19px;
      margin-bottom: 19px;
      border-bottom: 1px solid #E2D6CB;
    }
    .form-title {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }
    .btn {
      margin-top: 95px;
      text-align: right;
      button {
        font-size: 16px;
        margin-right: 10px;
        display: inline-block;
        width: 96px;
        height: 40px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        color: #fff;
      }
      .cancel {
        background: #B7BEBC;
      }
      .sure {
        background: #54AB8E;
      }
    }
  }
</style>