<template>
    <div class="pay-rate-class">
        <div class="title-border">
            <div>支付费率账单</div>
            <div style="font-size: 16px;padding-top: 10px;">您将续费以下2个店铺的套餐，请确认：</div>
        </div>
        <!--续费店铺表格-->
        <el-table
                max-height="200"
                :data="tableData"
                style="width: 100%">
            <el-table-column
                    label="店铺名称"
                    show-overflow-tooltip
                    width="110">
                <template slot-scope="scope">
                    <div>{{ scope.row.name }}</div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="package"
                    label="账单周期"
                    width="180">
                <template slot-scope="scope">
                    <div>{{ scope.row.package }}</div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="state"
                    label="金额"
            >
                <template slot-scope="scope">
                    <div>{{ scope.row.package }}</div>
                </template>
            </el-table-column>
        </el-table>
        <!--取消&确定按钮-->
        <div class="btn">
            <span class="cancel" @click="handleCancel()">取消</span>
            <span class="sure" @click="handleSure()">去续费</span>
        </div>
    </div>
</template>

<script>

    export default {
        name: "renewStore",
        data() {
            return {
                tableData: [
                    {
                        time: '2016-05-03',
                        name: '王小虎',
                        package: '试用版',
                        state: '正常'
                    },
                    {
                        time: '2016-05-03',
                        name: '家里带哦',
                        package: '试用版',
                        state: '欠费'
                    },
                    {
                        time: '2016-05-03',
                        name: '发顺丰蓝色',
                        package: '试用版',
                        state: '正常'
                    },
                ],
            };
        },
        methods: {
            handleCancel() {
                this.$parent.$parent.renewStoreDialog = false
            },
            handleSure() {
                this.$parent.$parent.renewStoreDialog = false
                this.$router.push({path: 'completePayment'})
            }

        },
    }
</script>

<style scoped lang="scss">
    .pay-rate-class {
        .title-border {
            padding-bottom: 19px;
            border-bottom: 2px solid #E2D6CB;
        }
        .btn {
            position: absolute;
            bottom: 50px;
            right: 95px;
            span {
                display: inline-block;
                width: 120px;
                height: 52px;
                line-height: 52px;
                text-align: center;
                border-radius: 8px;
                cursor: pointer;
            }
            .cancel {
                background: #B7BEBC;
                margin-right: 30px;
            }
            .sure {
                background: #54AB8E;
            }
        }
    }
</style>